import React from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { imageUrl } from "../../baseApi";

const FirstBanner = ({ style, data }) => {
  return (
    <div className={style.banner}>
      <div
        className={style.innerBanner}
        style={{ backgroundImage: `url(${imageUrl}${data?.image.url})` }}
      >
        <p>{data?.subtitle}</p>
        <ReactMarkdown>{data?.title}</ReactMarkdown>
        <Link to="/shop">- eksploro më shumë -</Link>
      </div>
    </div>
  );
};

export default FirstBanner;
