import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/Homepage";
import NotFound from "./pages/NotFound";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Shop from "./pages/Shop";
import ProductDetail from "./pages/ProductDetail";
import Cart from "./pages/Cart";
import Thankyou from "./pages/Thankyou";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Shipping from "./pages/Shipping";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/contact-us" element={<Contact />} />
    <Route path="/shop" element={<Shop />} />
    <Route path="/shop/:id" element={<ProductDetail />} />
    <Route path="/cart" element={<Cart />} />
    <Route path="/thankyou" element={<Thankyou />} />
    <Route path="/privacy-policy" element={<Privacy />} />
    <Route path="/terms-of-service" element={<Terms />} />
    <Route path="/shipping" element={<Shipping />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
