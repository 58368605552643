import React from "react";
import style from "../../styles/loader.module.scss";
import logo from "../../assets/images/logo2.svg";
const Loader = () => {
  return (
    <div className={style.container}>
      <img src={logo} className={style.loader} alt="Logo" />
    </div>
  );
};

export default Loader;
