import React from "react";

import ProductsSlider from "../shared/ProductsSlider";

const BestProducts = ({ style, bestProducts }) => {
  // console.log(bestProducts);
  return (
    <div className={style.productsContainer}>
      <p>TOP PRODUKTET</p>
      <h1>Produktet tona më të shitura</h1>

      <ProductsSlider relatedProducts={bestProducts} />
    </div>
  );
};

export default BestProducts;
