/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import style from "../../styles/navbar.module.scss";
import { BiPhoneCall } from "react-icons/bi";
import { IoCartOutline } from "react-icons/io5";
import { IoMdArrowForward } from "react-icons/io";

import { Link } from "react-router-dom";
import logo from "../../assets/images/logo3.png";
import { Squash as Hamburger } from "hamburger-react";
import { IoMdClose } from "react-icons/io";
import { useCart } from "react-use-cart";
import { GlobalContext } from "../../context/GlobalContext";

const Navbar = () => {
  const { getSale, sale } = useContext(GlobalContext);
  const { items } = useCart();
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  useEffect(() => {
    getSale();
  }, []);
  return (
    <div className={style.container}>
      {sale ? (
        sale?.Sale?.show ? (
          <div className={style.orderLine}>
            <div className={style.innerContainer}>
              <div className={style.order}>
                <BiPhoneCall color="white" size={25} />{" "}
                <a href="tel:+38348808287">Porosit: +383 48 80 82 87</a>
              </div>
              <div className={style.order}>
                <p>{sale?.Sale?.saleText} </p>
              </div>
              <div className={style.order}>
                <Link to={"/shop"}>
                  <IoMdArrowForward size={25} />
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      <div className={style.navbar}>
        <div className={style.innerContainer}>
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
          <div className={style.links}>
            <Link to={"/"}>Ballina</Link>
            <Link to={"/shop"}>Shop</Link>
            <Link to={"/about-us"}>Rreth nesh</Link>
            <Link to={"/contact-us"}>Kontakt</Link>
          </div>
          <div className={style.cart}>
            <Link to="/cart">
              <IoCartOutline color="#5a5172" size={25} />{" "}
              {items.length > 0 ? `(${items.length})` : ``}
            </Link>
            <div className={style.burger}>
              <Hamburger toggled={isOpen} toggle={setOpen} size={20} />
            </div>
          </div>
        </div>
      </div>
      {isOpen ? (
        <div
          className={`${style.mobileNavbar}  animate__animated animate__fadeInDown`}
        >
          <IoMdClose
            size={25}
            onClick={() => {
              setOpen(false);
            }}
          />

          <Link
            to="/"
            className={style.logo}
            onClick={() => {
              setOpen(false);
            }}
          >
            <img src={logo} alt="Logo" />
          </Link>
          <div className={style.mobileLinks}>
            <Link
              to={"/"}
              onClick={() => {
                setOpen(false);
              }}
            >
              Ballina
            </Link>
            <Link
              to={"/shop"}
              onClick={() => {
                setOpen(false);
              }}
            >
              Shop
            </Link>
            <Link
              to={"/about-us"}
              onClick={() => {
                setOpen(false);
              }}
            >
              Rreth nesh
            </Link>

            <Link
              to={"/contact-us"}
              onClick={() => {
                setOpen(false);
              }}
            >
              Kontakt
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Navbar;
