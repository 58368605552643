import React, { useEffect, useState } from "react";

const Infos = ({ style, image, title, data, type, address }) => {
  const [link, setLink] = useState("");

  useEffect(() => {
    if (type === "email") {
      setLink(`mailto:${data}`);
    } else if (type === "phone") {
      setLink(`tel:${data}`);
    } else if (type === "location") {
      setLink(address);
    }
  }, [type, data, address]);
  return (
    <div className={style.infos}>
      <img src={image} alt="Icons" />
      <div className={style.data}>
        <h5>{title}</h5>
        <a href={link} target="_blank" rel="noreferrer">
          {data}
        </a>
      </div>
    </div>
  );
};

export default Infos;
