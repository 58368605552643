import React, { useContext, useState } from "react";
import style from "../styles/cart.module.scss";
import Banner from "../components/shared/Banner";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import CartProduct from "../components/shared/CartProduct";
import { useCart } from "react-use-cart";
import { GlobalContext } from "../context/GlobalContext";
import { OrbitProgress } from "react-loading-indicators";
import ReactPixel from "react-facebook-pixel";

const Cart = () => {
  const navigate = useNavigate();
  const { items, emptyCart } = useCart();
  const { makeOrder } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    address: "",
    city: "",
    postalCode: "",
    phoneNumber: "",
    country: "Republika e Kosovës",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.fullName.trim())
      newErrors.fullName = "Emri dhe mbiemri është i detyrueshëm.";
    if (!formData.address.trim())
      newErrors.address = "Adresa është e detyrueshme.";
    if (!formData.city.trim()) newErrors.city = "Qyteti është i detyrueshëm.";
    // if (!formData.postalCode.trim() || !/^\d{4,5}$/.test(formData.postalCode))
    //   newErrors.postalCode = "Kodi postar duhet të përmbajë 4-5 shifra.";
    if (!formData.phoneNumber.trim())
      newErrors.phoneNumber = "Numri i telefonit është i detyrueshëm.";
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!formData.email) {
      newErrors.email = "Adresa e emailit është e detyrueshme.";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Ju lutem jepni një adresë emaili të vlefshme.";
    }
    return newErrors;
  };

  function calculateTotal(products) {
    let totalAmount = 0;

    products.forEach((product) => {
      if (product.discount > 0) {
        product.itemTotal =
          (product.price - (product.price * product.discount) / 100) *
          product.quantity;
      } else {
        product.itemTotal = product.price * product.quantity;
      }
      totalAmount += product.itemTotal;
    });

    return totalAmount;
  }

  function calculateTotalWithDiscount(products) {
    let totalAmount = 0;

    products.forEach((product) => {
      if (product.discount > 0) {
        product.itemTotal =
          (product.price - (product.price * product.discount) / 100) *
          product.quantity;
      } else {
        product.itemTotal =
          (product.price - (product.price * 10) / 100) * product.quantity;
      }
      totalAmount += product.itemTotal;
    });

    return totalAmount;
  }

  function calculateSubtotalWithoutDiscount(products) {
    let subtotal = 0;

    products.forEach((product) => {
      subtotal += product.price * product.quantity;
    });

    return subtotal;
  }

  function checkDeliveryStatus(totalAmount) {
    return totalAmount >= 10 ? false : true;
  }

  function applyDiscounts(products, totalAmount) {
    let discountApplied = false;

    if (totalAmount >= 20) {
      products.forEach((product) => {
        if (product.discount === 0) {
          product.itemTotal = product.itemTotal * 0.9;
        }
      });
      discountApplied = true;
    }

    return discountApplied;
  }

  function processOrder(products) {
    const subtotalWithoutDiscount = calculateTotal(products);

    let totalAmount = calculateTotal(products);

    let deliveryStatus = checkDeliveryStatus(totalAmount);

    const discountApplied = applyDiscounts(products, subtotalWithoutDiscount);

    totalAmount = discountApplied
      ? calculateTotalWithDiscount(products)
      : calculateTotal(products);

    return {
      products,
      subtotalWithoutDiscount,
      totalAmount,
      deliveryStatus,
      discountApplied,
    };
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      const data = {
        data: {
          userEmail: formData.email,
          userAddress: `${formData.address}, ${formData.city}, ${formData.postalCode}, ${formData.country} `,
          userPhone: formData.phoneNumber,
          OrderProduct: items.map((item) => {
            return {
              quantity: item.quantity,
              product: item.documentId,
            };
          }),
          total: processOrder(items).deliveryStatus
            ? (processOrder(items).totalAmount + 2).toFixed(2)
            : processOrder(items).totalAmount.toFixed(2),
          name: formData.fullName,
          delivery: processOrder(items).deliveryStatus,
          discount: processOrder(items).discountApplied,
          subtotal: processOrder(items).subtotalWithoutDiscount.toFixed(2),
        },
      };
      const purchaseData = {
        content_ids: items.map((item) => {
          return {
            product: item.documentId,
          };
        }),
        value: processOrder(items).deliveryStatus
          ? (processOrder(items).totalAmount + 2).toFixed(2)
          : processOrder(items).totalAmount.toFixed(2),
        currency: "EUR",
        content_type: "product",
      };
      ReactPixel.track("Purchase", purchaseData);

      await makeOrder(data);

      navigate("/thankyou");
      emptyCart();
    }
    setLoading(false);
  };
  // console.log("subtotal", processOrder(items).subtotalWithoutDiscount);
  // console.log("total", processOrder(items).totalAmount);
  // console.log("deliveryStatus", processOrder(items).deliveryStatus);
  // console.log("discountApplied", processOrder(items).discountApplied);

  return (
    <div className={style.wrapper}>
      <Banner
        current={"Shporta"}
        title={"Shporta Juaj"}
        from={[
          { name: "Ballina", link: "/" },
          { name: "Shop", link: "/shop" },
        ]}
      />
      {items.length > 0 ? (
        <div className={style.cart}>
          <div className={style.products}>
            {items.map((item, key) => {
              return <CartProduct key={key} product={item} />;
            })}
          </div>
          <div className={style.billingInfo}>
            <form onSubmit={handleSubmit}>
              <h1>Dërgo Porosinë</h1>
              <div>
                <label>Emri dhe Mbiemri</label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  placeholder="Shkruani emrin dhe mbiemrin tuaj"
                />
                {errors.fullName && (
                  <p style={{ color: "red" }}>{errors.fullName}</p>
                )}
              </div>
              <div>
                <label>Emaili</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Shkruani emailin tuaj"
                />
                {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
              </div>
              <div>
                <label>Adresa</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Shkruani adresën tuaj"
                />
                {errors.address && (
                  <p style={{ color: "red" }}>{errors.address}</p>
                )}
              </div>
              <div>
                <label>Qyteti</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="Shkruani qytetin tuaj"
                />
                {errors.city && <p style={{ color: "red" }}>{errors.city}</p>}
              </div>
              <div>
                <label>Kodi Postar(opsional)</label>
                <input
                  type="text"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleChange}
                  placeholder="Shkruani kodin postar"
                />
                {errors.postalCode && (
                  <p style={{ color: "red" }}>{errors.postalCode}</p>
                )}
              </div>
              <div>
                <label>Numri i Telefonit</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Shkruani numrin tuaj të telefonit"
                />
                {errors.phoneNumber && (
                  <p style={{ color: "red" }}>{errors.phoneNumber}</p>
                )}
              </div>
              <div>
                <label>Shteti</label>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  disabled
                />
              </div>
              <div className={style.billingDetails}>
                <h3>
                  Nëntotali:{" "}
                  {processOrder(items).subtotalWithoutDiscount.toFixed(2)}€
                </h3>
                {processOrder(items).discountApplied ? (
                  <h4>
                    Zbritje: -10%{" "}
                    <span>
                      Zbritja aplikohet vetëm në produktet pa zbritje!
                    </span>
                  </h4>
                ) : (
                  <></>
                )}
                <h4>
                  Posta: {processOrder(items).deliveryStatus ? "2€" : "Falas"}{" "}
                </h4>
                <h2>
                  Totali:{" "}
                  {processOrder(items).deliveryStatus
                    ? (processOrder(items).totalAmount + 2).toFixed(2)
                    : processOrder(items).totalAmount.toFixed(2)}
                  €
                </h2>
              </div>
              {loading ? (
                <div className={style.loader}>
                  <OrbitProgress
                    color="#5a5172"
                    size="small"
                    text=""
                    textColor=""
                  />
                </div>
              ) : (
                <button type="submit">Dërgo</button>
              )}
            </form>
          </div>
        </div>
      ) : (
        <div className={style.empty}>
          <h1>Momentalisht nuk keni zgjedhur asnjë produkt</h1>
          <Link to={"/shop"}>
            Shop <IoIosArrowForward fontWeight={"bold"} />
          </Link>
        </div>
      )}
    </div>
  );
};

export default Cart;
