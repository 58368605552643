import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/fonts.css";
import "./styles/global.css";
import App from "./App";
import { GlobalProvider } from "./context/GlobalContext";
import { CartProvider } from "react-use-cart";
import ReactPixel from "react-facebook-pixel";

import "animate.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init("1233498177729105", options);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalProvider>
      <CartProvider>
        <App />
      </CartProvider>
    </GlobalProvider>
  </React.StrictMode>
);
