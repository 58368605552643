import axios from "axios";
import { toast } from "react-toastify";

export const imageUrl = "https://cms.onbeautyaesthetics.com";
const api = axios.create({
  baseURL: "https://cms.onbeautyaesthetics.com/api",
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;

      if (status === 401) {
        window.location.href = "/";
      }

      if (status === 403) {
        console.error("Access denied.");
        toast.error("Access denied");
      }
      if (status === 404) {
        console.error("Resource not found.");
        toast.error("Resource not found.");
      }
    } else {
      console.error("Network error:", error.message);
      toast.error(`Network error: ${error.message}`);
    }

    return Promise.reject(error);
  }
);

export default api;
