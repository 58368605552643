/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import style from "../styles/contact.module.scss";
import Banner from "../components/shared/Banner";
import ContactSection from "../components/contact/ContactSection";
import Loader from "../components/shared/Loader";
import { GlobalContext } from "../context/GlobalContext";
import parse from "html-react-parser";
import withHelmet from "../seo/withHelmet";
import { contactUsHelmetProps } from "../seo/helmetProps";

const Contact = () => {
  const { contact, getContact, loading } = useContext(GlobalContext);
  useEffect(() => {
    getContact();
  }, []);
  return (
    <>
      {loading || contact === undefined ? (
        <Loader />
      ) : (
        <div className={style.wrapper}>
          <Banner
            current={"Kontakt"}
            title={"Kontakt"}
            from={[
              {
                name: "Ballina",
                link: "/",
              },
            ]}
          />
          <ContactSection style={style} data={contact?.Contact} />
          {parse(contact?.Contact?.locationIframe)}
        </div>
      )}
    </>
  );
};

export default withHelmet(Contact, contactUsHelmetProps);
