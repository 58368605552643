import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const Hashtags = ({ style, data = [] }) => {
  return (
    <>
      <Swiper
        className={style.hashtagsSwiper}
        slidesPerView={5}
        spaceBetween={30}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        breakpoints={{
          1200: {
            slidesPerView: 5,
          },
          1024: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          650: {
            slidesPerView: 3,
          },
          480: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
          300: {
            slidesPerView: 1,
          },
        }}
      >
        {data?.map((hashtags) => {
          return (
            <SwiperSlide className={style.hashtags} key={hashtags?.id}>
              <h4>#{hashtags?.hashtags}</h4>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default Hashtags;
