import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import style from "../../styles/productsSlider.module.scss";
import ProductCard from "./ProductCard";
const ProductsSlider = ({ relatedProducts = [] }) => {
  return (
    <Swiper
      className={style.productsSwiper}
      slidesPerView={5}
      spaceBetween={30}
      loop
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      breakpoints={{
        1200: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
        },
        650: {
          slidesPerView: 3,
        },
        480: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 1,
        },
        300: {
          slidesPerView: 1,
        },
      }}
    >
      {relatedProducts.map((product) => {
        return (
          <SwiperSlide className={style.products} key={product.id}>
            <ProductCard product={product} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default ProductsSlider;
