import React from "react";
import style from "../../styles/breadcrumb.module.scss";
import { Link } from "react-router-dom";
import { TbPointFilled } from "react-icons/tb";

const BreadCrumb = ({ from = [], current }) => {
  return (
    <div className={style.crumbs}>
      {from.map((fr) => {
        return (
          <div key={fr.link}>
            <Link to={fr.link}>{fr.name}</Link>
            <TbPointFilled
              color="white
            "
            />
          </div>
        );
      })}
      <p>{current}</p>
    </div>
  );
};

export default BreadCrumb;
