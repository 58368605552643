import React from "react";
import style from "../../styles/banner.module.scss";
import BreadCrumb from "./BreadCrumb";
const Banner = ({ title, from, current }) => {
  return (
    <div className={style.banner}>
      <h1>{title}</h1>
      <BreadCrumb from={from} current={current} />
    </div>
  );
};

export default Banner;
