import React from "react";
import style from "../../styles/productsCard.module.scss";
import { Link } from "react-router-dom";
// import photon from "../../assets/images/e513.jpg";
import { imageUrl } from "../../baseApi";

const ProductCard = ({ product }) => {
  return (
    <Link to={`/shop/${product?.documentId}`} className={style.card}>
      <div
        className={style.image}
        style={{ backgroundImage: `url(${imageUrl + product?.photos[0].url})` }}
      >
        {product?.discount > 0 ? (
          <p className={style.discount}>{"-" + product?.discount + "%"}</p>
        ) : (
          <></>
        )}
        {product?.topProduct ? (
          <p className={product?.discount === 0 ? style.discount : style.top}>
            {"TOP"}
          </p>
        ) : (
          <></>
        )}
      </div>
      <h4>{product ? product.Title : "Porduct Title"}</h4>
      <p>
        {product?.discount > 0
          ? (product.price - (product.price * product.discount) / 100).toFixed(
              2
            )
          : product?.price.toFixed(2)}
        €
      </p>
    </Link>
  );
};

export default ProductCard;
