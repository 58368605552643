import React, { useContext, useState } from "react";
import style from "../../styles/footer.module.scss";
import logo from "../../assets/images/logo2.svg";
import sent from "../../assets/icons/sent.svg";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { PiTiktokLogoThin } from "react-icons/pi";

import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineEmail } from "react-icons/md";
import { GlobalContext } from "../../context/GlobalContext";

const Footer = () => {
  const { registerToNewsletter } = useContext(GlobalContext);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");
  const [errors, setErrors] = useState({
    email: "",
  });
  const validateForm = () => {
    const newErrors = {
      fullName: "",
      email: "",
      message: "",
    };

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email) {
      newErrors.email = "Adresa e emailit është e detyrueshme.";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Ju lutem jepni një adresë emaili të vlefshme.";
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  const submitEmail = async () => {
    setLoading(true);
    await registerToNewsletter({ email });
    setLoading(false);
  };
  return (
    <div className={style.container}>
      <div className={style.innerContainer}>
        <div className={style.col}>
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
          <h5>Vendosni email për të marrë oferta</h5>
          <p>dhe regjistrohuni ne listen tonë</p>
          <div className={style.newsLetter}>
            <input
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Jepni emailin tuaj"
            />
            {loading ? (
              <></>
            ) : (
              <img
                src={sent}
                alt="Sent Icon"
                onClick={async () => {
                  if (validateForm()) {
                    await submitEmail();
                  }
                }}
              />
            )}
            <p>{errors.email}</p>
          </div>
        </div>
        <div className={style.col}>
          <Link to="/">Ballina</Link>
          <Link to="/shop">Shop</Link>
          <Link to="/about-us">Rreth nesh</Link>
          <Link to="/contact-us">Kontakt</Link>
          <Link to="/cart">Shporta</Link>
        </div>
        <div className={style.col}>
          <Link to="/terms-of-service">Kushtet e përdorimit</Link>
          <Link to="/privacy-policy">Politika e privatësisë</Link>
          <Link to="/shipping">Transporti</Link>
          <Link to="/" className={style.hidden}>
            Transporti
          </Link>
          <Link to="/" className={style.hidden}>
            Transporti
          </Link>
        </div>
        <div className={style.col}>
          <a
            href="https://www.instagram.com/onbeauty.aesthetics/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram size={20} /> Instagram
          </a>
          <a
            href="https://www.facebook.com/onbeautyaesthetics"
            target="_blank"
            rel="noreferrer"
          >
            <CiFacebook size={25} /> Facebook
          </a>
          <a
            href="https://www.tiktok.com/@onbeautyaesthetics"
            target="_blank"
            rel="noreferrer"
          >
            <PiTiktokLogoThin size={25} /> TIKTOK
          </a>
          <a href="tel:+38348808287" target="_blank" rel="noreferrer">
            <BiPhoneCall size={25} /> +383 48 80 82 87
          </a>
          <a
            href="mailto:contact@onbeautyaesthetics.com"
            target="_blank"
            rel="noreferrer"
          >
            <MdOutlineEmail size={25} /> contact@onbeautyaesthetics.com
          </a>
        </div>
      </div>
      <div className={style.hr} />
      <div className={style.copyright}>
        <p>&copy; {new Date().getFullYear()} On Beauty Aesthetics</p>
      </div>
    </div>
  );
};

export default Footer;
