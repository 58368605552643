import React from "react";
import { Helmet } from "react-helmet";

const withHelmet = (WrappedComponent, helmetProps = {}) => {
  const HOC = (props) => {
    const {
      title,
      meta = [],
      link = [],
      script = [],
      style = [],
    } = helmetProps;

    return (
      <>
        <Helmet>
          {title && <title>{title}</title>}

          {meta.map((metaProps, index) => (
            <meta key={`meta-${index}`} {...metaProps} />
          ))}

          {link.map((linkProps, index) => (
            <link key={`link-${index}`} {...linkProps} />
          ))}

          {script.map((scriptProps, index) => (
            <script key={`script-${index}`} {...scriptProps} />
          ))}

          {style.map((styleProps, index) => (
            <style key={`style-${index}`} {...styleProps}>
              {styleProps.children || ""}
            </style>
          ))}
        </Helmet>

        <WrappedComponent {...props} />
      </>
    );
  };

  HOC.displayName = `withHelmet(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return HOC;
};

export default withHelmet;
