export const homepageHelmetProps = {
  title:
    "On Beauty Aesthetics - Produkte Premium për Kujdesin e Lëkurës | Premium Skincare Products",
  meta: [
    {
      name: "description",
      content:
        "Zbuloni produkte premium për kujdesin e lëkurës, duke përfshirë kremra, dyll, serume, locione, qumësht trupi dhe masazhe me vaj. Përmirësoni bukurinë tuaj me On Beauty Aesthetics. | Discover premium skincare products, including creams, waxes, serums, lotions, body milk, and massage oils. Enhance your beauty with On Beauty Aesthetics.",
    },
    {
      name: "keywords",
      content:
        "skincare, creams, wax, serums, lotions, body milk, massage oil, beauty products, premium aesthetics, skincare Kosovo, kujdes lëkure, kremra, dyll, serume, locione, qumësht trupi, vaj për masazh, produkte bukurie, estetika premium",
    },
  ],
  link: [{ rel: "canonical", href: "https://www.onbeautyaesthetics.com" }],
};

export const aboutUsHelmetProps = {
  title: "Rreth Nesh - On Beauty Aesthetics | About Us - On Beauty Aesthetics",
  meta: [
    {
      name: "description",
      content:
        "Mësoni më shumë rreth On Beauty Aesthetics, burimi juaj i besueshëm për produkte premium për kujdesin e lëkurës. Ne jemi të specializuar në kremra, serume, locione dhe më shumë për të përmirësuar bukurinë tuaj. | Learn about On Beauty Aesthetics, your trusted source for premium skincare products. We specialize in creams, serums, lotions, and more to enhance your beauty. ",
    },
    {
      name: "keywords",
      content:
        "about us, skincare experts, premium beauty, creams, serums, lotions, body milk, massage oil, kujdes lëkure, ekspertë të bukurisë, produkte premium, kremra, serume, locione, qumësht trupi, vaj për masazh",
    },
  ],
  link: [
    { rel: "canonical", href: "https://www.onbeautyaesthetics.com/about-us" },
  ],
};

export const contactUsHelmetProps = {
  title:
    "Na Kontaktoni - On Beauty Aesthetics | Contact Us - On Beauty Aesthetics",
  meta: [
    {
      name: "description",
      content:
        "Na kontaktoni për pyetje rreth produkteve tona premium për kujdesin e lëkurës, duke përfshirë kremra, dyll, serume dhe më shumë. Jemi këtu për t'ju ndihmuar. | Contact On Beauty Aesthetics for inquiries about our premium skincare products, including creams, waxes, serums, and more. We're here to help.",
    },
    {
      name: "keywords",
      content:
        "contact us, skincare support, premium products, creams, serums, lotions, massage oil, na kontaktoni, produkte kujdesi lëkurë, produkte premium, kremra, serume, locione, vaj për masazh",
    },
  ],
  link: [
    { rel: "canonical", href: "https://www.onbeautyaesthetics.com/contact-us" },
  ],
};

export const shopHelmetProps = {
  title:
    "Dyqan për Produkte të Kujdesit të Lëkurës - On Beauty Aesthetics | Shop Skincare Products - On Beauty Aesthetics",
  meta: [
    {
      name: "description",
      content:
        "Shfletoni dyqanin tonë për produkte premium për kujdesin e lëkurës. Blini kremra, serume, locione, qumësht trupi dhe masazhe me vaj. Përmirësoni bukurinë tuaj me produktet tona cilësore. | Explore our shop for premium skincare products. Buy creams, serums, lotions, body milk, and massage oils. Enhance your beauty with our top-quality products.",
    },
    {
      name: "keywords",
      content:
        "shop skincare, creams, serums, lotions, body milk, massage oil, premium beauty, skincare Kosovo, dyqan kujdesi lëkure, kremra, serume, locione, qumësht trupi, vaj për masazh, produkte premium",
    },
  ],
  link: [{ rel: "canonical", href: "https://www.onbeautyaesthetics.com/shop" }],
};

export const singleProductHelmetProps = {
  title:
    "Produkt Premium për Kujdesin e Lëkurës - On Beauty Aesthetics | Premium Skincare Product - On Beauty Aesthetics ",
  meta: [
    {
      name: "description",
      content:
        "Zbuloni përfitimet e produkteve tona premium për kujdesin e lëkurës, duke përfshirë kremra, serume, locione, qumësht trupi dhe masazhe me vaj. Ideale për përmirësimin e bukurisë dhe relaksimit. | Discover the benefits of our premium skincare products, including creams, serums, lotions, body milk, and massage oils. Perfect for enhancing beauty and relaxation. ",
    },
    {
      name: "keywords",
      content:
        "skincare product, premium cream, serum, lotion, body milk, massage oil, beauty product, produkt kujdesi lëkure, krem premium, serum, locion, qumësht trupi, vaj për masazh, produkt bukurie",
    },
  ],
  link: [
    {
      rel: "canonical",
      href: "https://www.onbeautyaesthetics.com/product/unique-product-name",
    },
  ],
};
