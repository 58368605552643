import React, { useState, useEffect } from "react";
import style from "../../styles/quantity.module.scss";
import { useCart } from "react-use-cart";

const Quantity = ({
  onQuantityChange = () => {},
  itemId,
  isCart,
  quantity = 1,
}) => {
  const [count, setCount] = useState(quantity);
  const { updateItemQuantity, items } = useCart();

  const increase = () => {
    setCount((prev) => prev + 1);
  };
  const decrease = () => {
    setCount((prev) => Math.max(1, prev - 1));
  };

  useEffect(() => {
    onQuantityChange(count);

    if (items.length > 0 && isCart) {
      updateItemQuantity(itemId, count);
    }
  }, [count]);

  return (
    <div className={style.button}>
      <button onClick={decrease}>-</button>
      <p>{count}</p>
      <button onClick={increase}>+</button>
    </div>
  );
};

export default Quantity;
