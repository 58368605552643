import React from "react";
import style from "../styles/notfound.module.scss";
import image from "../assets/images/404.png";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className={style.container}>
      <img src={image} alt="404" />
      <h3>Na vjen keq, por nuk mund të gjejmë atë që po kërkoni.</h3>
      <Link to="/">Kthehu në faqen kryesore</Link>
    </div>
  );
};

export default NotFound;
