import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/shared/Navbar";
import Footer from "./components/shared/Footer";
import ScrollToTop from "./utils/ScrollToTop";
import ReactPixel from "react-facebook-pixel";
const App = () => {
  useEffect(() => {
    const loadGoogleTag = () => {
      const script = document.createElement("script");
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-CM72CT7MG0";
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-CM72CT7MG0");
      };
    };

    loadGoogleTag();
    ReactPixel.pageView();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <ToastContainer />
      <AppRoutes />
      <Footer />
    </Router>
  );
};

export default App;
