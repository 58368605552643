import React from "react";
import ReactPlayer from "react-player/lazy";

const Video = ({ style, data }) => {
  return (
    <div className={style.video}>
      <ReactPlayer url={data?.url} width={"100%"} height={"100%"} muted />
    </div>
  );
};

export default Video;
