import React from "react";
import ReactMarkdown from "react-markdown";
import bannerBg from "../../assets/images/about/bannerBg.png";

const OurStory = ({ style, data }) => {
  return (
    <div
      className={style.story}
      style={{ backgroundImage: `url(${bannerBg})` }}
    >
      <ReactMarkdown>{data}</ReactMarkdown>
    </div>
  );
};

export default OurStory;
