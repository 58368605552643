/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import style from "../../styles/cartProduct.module.scss";
import { MdDeleteOutline } from "react-icons/md";
import Quantity from "./Quantity";
import { useCart } from "react-use-cart";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { imageUrl } from "../../baseApi";

const CartProduct = ({ product }) => {
  const { removeItem, items } = useCart();
  const navigate = useNavigate();

  const [quantity, setQuantity] = useState();

  const shortTitle = (string = "") => {
    // if (string.length > 20) {
    //   return `${string.substring(0, 20)}...`;
    // }
    return string;
  };

  const addQuantityField = (quantity) => {
    setQuantity(quantity);
  };
  return (
    <div className={style.card}>
      <div className={style.prodData}>
        <div
          className={style.image}
          style={{
            backgroundImage: `url(${imageUrl + product?.photos[0].url})`,
          }}
        />
        <div className={style.data}>
          <h2> {shortTitle(product.Title)}</h2>
          <p>
            Çmimi:{" "}
            <span className={product?.discount > 0 ? style.discount : ""}>
              {product.price.toFixed(2)}€
            </span>
          </p>
          {product?.discount > 0 ? (
            <p>
              {(
                product?.price -
                (product?.price * product?.discount) / 100
              ).toFixed(2)}
              €
            </p>
          ) : (
            <></>
          )}

          <p className={style.total}>
            Total:{" "}
            {product?.discount > 0
              ? (
                  (product?.price -
                    (product?.price * product?.discount) / 100) *
                  product.quantity
                ).toFixed(2)
              : (product?.price * product.quantity).toFixed(2)}
            €
          </p>
        </div>
      </div>
      <div className={style.actions}>
        <Quantity
          isCart={true}
          itemId={product.id}
          onQuantityChange={addQuantityField}
          quantity={product.quantity}
        />
        <MdDeleteOutline
          size={20}
          onClick={() => {
            removeItem(product.id);
            toast.warn("Produkti u fshi nga shporta");
            if (items.length === 1) {
              navigate("/shop");
            }
          }}
        />
      </div>
    </div>
  );
};

export default CartProduct;
