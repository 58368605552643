/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import FirstBanner from "../components/homepage/FirstBanner";
import Hashtags from "../components/homepage/Hashtags";

import style from "../styles/homepage.module.scss";
import Categories from "../components/homepage/Categories";
import BestProducts from "../components/homepage/BestProducts";
import Video from "../components/homepage/Video";
import Brands from "../components/shared/Brands";
import Instagram from "../components/homepage/Instagram";
import Loader from "../components/shared/Loader";
import { GlobalContext } from "../context/GlobalContext";
import withHelmet from "../seo/withHelmet";
import { homepageHelmetProps } from "../seo/helmetProps";

const Homepage = () => {
  const { homepage, getHomepage, loading, homeCategories, bestProducts } =
    useContext(GlobalContext);
  useEffect(() => {
    getHomepage();
  }, []);
  return (
    <>
      {loading ||
      homepage === undefined ||
      homeCategories === undefined ||
      bestProducts === undefined ? (
        <Loader />
      ) : (
        <div className={style.wrapper}>
          <FirstBanner style={style} data={homepage?.Banner} />
          <Hashtags style={style} data={homepage?.Hashtags} />
          <Categories style={style} data={homeCategories} />
          <BestProducts style={style} bestProducts={bestProducts} />
          <Video style={style} data={homepage?.Youtube} />
          <Brands data={homepage?.Brands} />
          <Instagram style={style} data={homepage?.Instagram} />
        </div>
      )}
    </>
  );
};

export default withHelmet(Homepage, homepageHelmetProps);
