import React, { useContext, useState } from "react";
import Infos from "./Infos";
import touch from "../../assets/icons/touch.svg";
import address from "../../assets/icons/address.svg";
import phone from "../../assets/icons/phone.svg";
import logo from "../../assets/images/logo3.png";
import { OrbitProgress } from "react-loading-indicators";
import { GlobalContext } from "../../context/GlobalContext";

const ContactSection = ({ style, data }) => {
  const { sendMessage } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const validateForm = () => {
    const newErrors = {
      fullName: "",
      email: "",
      message: "",
    };

    if (!fullName) {
      newErrors.fullName = "Emri dhe Mbiemri janë të detyrueshëm.";
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email) {
      newErrors.email = "Adresa e emailit është e detyrueshme.";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Ju lutem jepni një adresë emaili të vlefshme.";
    }

    if (!message) {
      newErrors.message = "Mesazhi është i detyrueshëm.";
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  const resetData = () => {
    setEmail("");
    setFullName("");
    setMessage("");
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (validateForm()) {
      // console.log("Form submitted!");
      await sendMessage({
        name: fullName,
        email: email,
        message: message,
      });
    }

    resetData();
    setLoading(false);
  };
  return (
    <div className={style.contact}>
      <div className={style.data}>
        <img src={logo} alt="On Beauty Aesthetics" className={style.logo} />
        <Infos
          style={style}
          image={touch}
          title={"Email"}
          data={data?.email}
          type={"email"}
          key={"Email"}
        />
        <Infos
          style={style}
          image={address}
          title={"Lokacioni i sallonit"}
          data={
            "Kori i Bardhë, Dardani, Nr. 89, Prishtinë, Republika e Kosovës"
          }
          type={"location"}
          address={data?.salonLocationUrl}
          key={"Address"}
        />
        <Infos
          style={style}
          image={address}
          title={"Lokacioni i depos"}
          data={"Tetova 17, Prishtinë, Republika e Kosovës"}
          type={"location"}
          address={data?.magazineLocationUrl}
          key={"AddressDepo"}
        />
        <Infos
          style={style}
          image={phone}
          title={"Telefon"}
          data={data?.phoneNumber}
          type={"phone"}
          key={"Phone"}
        />
        <div className={style.blankSquare} />
      </div>
      <form onSubmit={handleSubmit}>
        <h1>Na kontaktoni direkt nga website ynë</h1>
        <div>
          <label htmlFor="fullName">Emri dhe Mbiemri</label>
          <input
            type="text"
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          {errors.fullName && <p className="error">{errors.fullName}</p>}
        </div>

        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>

        <div>
          <label htmlFor="message">Mesazhi</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          {errors.message && <p className="error">{errors.message}</p>}
        </div>

        {loading ? (
          <div className={style.loader}>
            <OrbitProgress color="#5a5172" size="small" text="" textColor="" />
          </div>
        ) : (
          <button type="submit" disabled={loading}>
            Dërgo
          </button>
        )}
      </form>
    </div>
  );
};

export default ContactSection;
