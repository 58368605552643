/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import style from "../styles/privacy.module.scss";
import { GlobalContext } from "../context/GlobalContext";
import ReactMarkdown from "react-markdown";
import Loader from "../components/shared/Loader";
const Privacy = () => {
  const { getPrivacy, privacy, loading } = useContext(GlobalContext);

  useEffect(() => {
    getPrivacy();
  }, []);
  return (
    <>
      {loading || privacy === undefined ? (
        <Loader />
      ) : (
        <div className={style.wrapper}>
          <ReactMarkdown>{privacy}</ReactMarkdown>
        </div>
      )}
    </>
  );
};

export default Privacy;
