/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import style from "../styles/about.module.scss";
import Banner from "../components/shared/Banner";
import FirstBanner from "../components/about/FirstBanner";
import OurStory from "../components/about/OurStory";
import Brands from "../components/shared/Brands";
import Loader from "../components/shared/Loader";
import { GlobalContext } from "../context/GlobalContext";
import withHelmet from "../seo/withHelmet";
import { aboutUsHelmetProps } from "../seo/helmetProps";
const AboutUs = () => {
  const { about, getAbout, loading } = useContext(GlobalContext);

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <>
      {loading || about === undefined ? (
        <Loader />
      ) : (
        <div className={style.wrapper}>
          <Banner
            title={"Rreth Nesh"}
            from={[
              {
                name: "Ballina",
                link: "/",
              },
            ]}
            current={"Rreth Nesh"}
          />
          <FirstBanner style={style} data={about?.Banner} />
          <OurStory style={style} data={about?.Content?.AboutUs} />
          <Brands data={about?.Brands} />
        </div>
      )}
    </>
  );
};

export default withHelmet(AboutUs, aboutUsHelmetProps);
