import React from "react";
import bannerBg from "../../assets/images/about/bannerBg.png";
import { imageUrl } from "../../baseApi";

const FirstBanner = ({ style, data }) => {
  return (
    <div
      className={style.firstBanner}
      style={{ backgroundImage: `url(${bannerBg})` }}
    >
      <img src={imageUrl + data?.bannerImage?.url} alt="About Banner" />
      <div className={style.content}>
        <h1>{data?.Title}</h1>
        <p>{data?.Description}</p>
      </div>
    </div>
  );
};

export default FirstBanner;
