import React from "react";
import style from "../styles/thankyou.module.scss";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo4.png";
const Thankyou = () => {
  return (
    <div className={style.container}>
      <img src={logo} alt="logo" />
      <h3>Ju faleminderit për porosinë tuaj!</h3>
      <h4>Ju do të pranoni në email detajet e porosisë </h4>
      <Link to="/">Kthehu në faqen kryesore</Link>
    </div>
  );
};

export default Thankyou;
